@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url("../fonts/SVN-Poppins/EOT/SVN-Poppins-Light.eot");
	src: url("../fonts/SVN-Poppins/EOT/SVN-Poppins-Light.eot?#iefix") format("embedded-opentype"),
		url("../fonts/SVN-Poppins/WOFF2/SVN-Poppins-Light.woff2") format("woff2"),
		url("../fonts/SVN-Poppins/WOFF/SVN-Poppins-Light.woff") format("woff"),
		url("../fonts/SVN-Poppins/TTF/SVN-Poppins-Light.ttf") format("truetype"),
		url("../fonts/SVN-Poppins/SVG/SVN-Poppins-Light.svg#poppins") format("svg");
}
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url("../fonts/SVN-Poppins/EOT/SVN-Poppins-LightItalic.eot");
	src: url("../fonts/SVN-Poppins/EOT/SVN-Poppins-LightItalic.eot?#iefix") format("embedded-opentype"),
		url("../fonts/SVN-Poppins/WOFF2/SVN-Poppins-LightItalic.woff2") format("woff2"),
		url("../fonts/SVN-Poppins/WOFF/SVN-Poppins-LightItalic.woff") format("woff"),
		url("../fonts/SVN-Poppins/TTF/SVN-Poppins-LightItalic.ttf") format("truetype"),
		url("../fonts/SVN-Poppins/SVG/SVN-Poppins-LightItalic.svg#poppins") format("svg");
}
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("../fonts/SVN-Poppins/EOT/SVN-Poppins-Regular.eot");
	src: url("../fonts/SVN-Poppins/EOT/SVN-Poppins-Regular.eot?#iefix") format("embedded-opentype"),
		url("../fonts/SVN-Poppins/WOFF2/SVN-Poppins-Regular.woff2") format("woff2"),
		url("../fonts/SVN-Poppins/WOFF/SVN-Poppins-Regular.woff") format("woff"),
		url("../fonts/SVN-Poppins/TTF/SVN-Poppins-Regular.ttf") format("truetype"),
		url("../fonts/SVN-Poppins/SVG/SVN-Poppins-Regular.svg#poppins") format("svg");
}
@font-face {
	font-family: "Poppins";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url("../fonts/SVN-Poppins/EOT/SVN-Poppins-RegularItalic.eot");
	src: url("../fonts/SVN-Poppins/EOT/SVN-Poppins-RegularItalic.eot?#iefix") format("embedded-opentype"),
		url("../fonts/SVN-Poppins/WOFF2/SVN-Poppins-RegularItalic.woff2") format("woff2"),
		url("../fonts/SVN-Poppins/WOFF/SVN-Poppins-RegularItalic.woff") format("woff"),
		url("../fonts/SVN-Poppins/TTF/SVN-Poppins-RegularItalic.ttf") format("truetype"),
		url("../fonts/SVN-Poppins/SVG/SVN-Poppins-RegularItalic.svg#poppins") format("svg");
}
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("../fonts/SVN-Poppins/EOT/SVN-Poppins-Medium.eot");
	src: url("../fonts/SVN-Poppins/EOT/SVN-Poppins-Medium.eot?#iefix") format("embedded-opentype"),
		url("../fonts/SVN-Poppins/WOFF2/SVN-Poppins-Medium.woff2") format("woff2"),
		url("../fonts/SVN-Poppins/WOFF/SVN-Poppins-Medium.woff") format("woff"),
		url("../fonts/SVN-Poppins/TTF/SVN-Poppins-Medium.ttf") format("truetype"),
		url("../fonts/SVN-Poppins/SVG/SVN-Poppins-Medium.svg#poppins") format("svg");
}
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url("../fonts/SVN-Poppins/EOT/SVN-Poppins-SemiBold.eot");
	src: url("../fonts/SVN-Poppins/EOT/SVN-Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
		url("../fonts/SVN-Poppins/WOFF2/SVN-Poppins-SemiBold.woff2") format("woff2"),
		url("../fonts/SVN-Poppins/WOFF/SVN-Poppins-SemiBold.woff") format("woff"),
		url("../fonts/SVN-Poppins/TTF/SVN-Poppins-SemiBold.ttf") format("truetype"),
		url("../fonts/SVN-Poppins/SVG/SVN-Poppins-SemiBold.svg#poppins") format("svg");
}
@font-face {
	font-family: "Poppins";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("../fonts/SVN-Poppins/EOT/SVN-Poppins-Bold.eot");
	src: url("../fonts/SVN-Poppins/EOT/SVN-Poppins-Bold.eot?#iefix") format("embedded-opentype"),
		url("../fonts/SVN-Poppins/WOFF2/SVN-Poppins-Bold.woff2") format("woff2"),
		url("../fonts/SVN-Poppins/WOFF/SVN-Poppins-Bold.woff") format("woff"),
		url("../fonts/SVN-Poppins/TTF/SVN-Poppins-Bold.ttf") format("truetype"),
		url("../fonts/SVN-Poppins/SVG/SVN-Poppins-Bold.svg#poppins") format("svg");
}
